<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg11 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-account-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <v-tabs>
            <v-tab> Básicos </v-tab>
            <v-tab> Dados </v-tab>
            <v-tab> Complementares </v-tab>
            <v-tab> Credito </v-tab>
            <v-tab-item>
              <template>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="auto" md="1" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.id_cliente"
                        label="ID"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.nome_cliente"
                        label="Cliente"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.celular_cli"
                        label="Celular"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.data_nac_cli"
                        label="Data Nascimento"
                        v-mask="'##/##/####'"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.credito"
                        label="Credito"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="auto" md="5" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.endereco_cli"
                        label="Endereço"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="5" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.compl_cli"
                        label="Complemento"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="4" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.bairro_cli"
                        label="Bairro"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="auto" md="4" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.cidade_cli"
                        label="Cidade"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.uf_cli"
                        label="Estado"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.cep_cli"
                        label="CEP"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-tab-item>
            <v-tab-item>
              <template>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.rg_cliente"
                        label="RG"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.cpf_cliente"
                        label="CPF"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="5" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.nome_mae"
                        label="Nome da mãe"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="5" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.nome_pai"
                        label="Nome da pai"
                        outlined
                      ></v-text-field>
                    </v-col> </v-row
                ></v-container>
              </template>
            </v-tab-item>
            <v-tab-item>
              <template>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="auto" md="4" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.naturalidade"
                        label="Naturalidade"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.uf_natural"
                        label="Estado Naturalidade"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.estado_civil"
                        label="Estado Cívil"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.residencia"
                        label="Residência"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.profissao"
                        label="Profissão"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.endereco_profi"
                        label="Endereço Profissional"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.nome_conju"
                        label="Nome Conjuge"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.data_nac_conju"
                        label="Data Nascimento Conjuge"
                        v-mask="'##-##-####'"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.celular_conju"
                        label="Celular Conjuge"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-tab-item>
            <v-tab-item>
              <template>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.ultima_compra"
                        label="Última Compra"
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="auto" md="4" align-self="center">
                      <v-autocomplete
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.idConvenio"
                        label="Convenio"
                        :items="conveniadaLista"
                        item-text="alias"
                        item-value="id"
                        outlined
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="8" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.referencia"
                        label="Referencia"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.data_consulta"
                        label="Consulta SPC"
                        v-mask="'##-##-####'"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="8" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="clienteLocal.obs"
                        label="Observações"
                        outlined
                      >
                        ></v-text-field
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-tab-item>
          </v-tabs>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ path: 'LojaClientesLista' })"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-6 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ path: 'LojaClientesComprasForm' })"
                  :disabled="comprasIconDisable"
                >
                  mdi-cart
                </v-icon>
              </template>
              <span>Compras</span>
            </v-tooltip>
          </template>
          <!-- <template>
            <tableCompras />
          </template> -->
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";
// import moment from "moment";
// import tableCompras from "./LojaClientesComprasLista";

const { mapState, mapActions } = createNamespacedHelpers("lojaClientes");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   clienteLocal: {
  //     nome_cliente: { required },
  //     email: { required, email },
  //     cliente: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "LojaClientesComprasForm",
    tituloForm: "",
    clienteLocal: {},
    agora: undefined,
    comprasIconDisable: false,
  }),
  mounted() {},
  computed: {
    ...mapState(["clienteSelecionado", "conveniadasSelect"]),
    // nomeErrors() {
    //   const errors = [];
    //   if (!this.$v.clienteLocal.nome_cliente.$dirty) return errors;
    //   !this.$v.clienteLocal.nome_cliente.required &&
    //     errors.push("Nome requerido.");
    //   return errors;
    // },
    // emailErrors() {
    //   const errors = [];
    //   if (!this.$v.clienteLocal.email.$dirty) return errors;
    //   !this.$v.clienteLocal.email.email && errors.push("E-mail inválido");
    //   !this.$v.clienteLocal.email.required && errors.push("E-mail requerido");
    //   return errors;
    // },
    // clienteErrors() {
    //   const errors = [];
    //   if (!this.$v.clienteLocal.cliente.$dirty) return errors;
    //   !this.$v.clienteLocal.cliente.required &&
    //     errors.push("Usuário requerido.");
    //   return errors;
    // },
    // senhaErrors() {
    //   const errors = [];
    //   if (!this.$v.clienteLocal.senha.$dirty) return errors;
    //   !this.$v.clienteLocal.senha.required && errors.push("Senha requerida.");
    //   return errors;
    // },
    conveniadaLista() {
      // console.log(this.conveniadas);
      return this.conveniadasSelect;
    },
  },

  watch: {
    clienteSelecionado(clienteNovo) {
      this.sincronizar(clienteNovo);
    },
  },

  created() {
    this.agora = new Date().toISOString();
    this.sincronizar(this.clienteSelecionado);
    if (this.clienteSelecionado) {
      this.tituloForm = "Editar Cliente";
    } else {
      this.tituloForm = "Novo Cliente";
      this.comprasIconDisable = true;
    }
    this.formataDatasDB();
    this.selectConveniadas(this.$store.state.login.licenca);
  },
  methods: {
    ...mapActions([
      "selecionarCliente",
      "resetarCliente",
      "criarCliente",
      "actEditarCliente",
      "listarConveniadas",
      "selectConveniadas",
    ]),

    selecionarClienteCompras() {
      // this.selecionarCliente({ cliente });
      this.$router.push({ name: "LojaClientesComprasLista" });
    },
    selecionarClienteEdicao(cliente) {
      this.selecionarCliente({ cliente });
    },
    formataDatasForm() {
      this.clienteLocal.data_nac_conju = this.mixDataFormD(
        this.clienteLocal.data_nac_conju
      );

      this.clienteLocal.ultima_compra = this.mixDataFormD(
        this.clienteLocal.ultima_compra
      );

      this.clienteLocal.data_consulta = this.mixDataFormD(
        this.clienteLocal.data_consulta
      );

      this.clienteLocal.data_nac_cli = this.mixDataFormD(
        this.clienteLocal.data_nac_cli
      );

      this.clienteLocal.data_cadastro = this.mixDataFormD(
        this.clienteLocal.data_cadastro
      );
    },
    formataDatasDB() {
      this.clienteLocal.data_nac_cli = this.mixDataDBD(
        this.clienteLocal.data_nac_cli
      );

      this.clienteLocal.ultima_compra = this.mixDataDBD(
        this.clienteLocal.ultima_compra
      );

      this.clienteLocal.data_nac_conju = this.mixDataDBD(
        this.clienteLocal.data_nac_conju
      );

      this.clienteLocal.data_consulta = this.mixDataDBD(
        this.clienteLocal.data_consulta
      );

      this.clienteLocal.data_cadastro = this.mixDataDBD(
        this.clienteLocal.data_cadastro
      );
    },

    async salvarCliente() {
      switch (this.clienteSelecionado) {
        case undefined:
          this.comprasIconDisable = false;
          this.clienteLocal.data_cadastro = this.mixDataAgoraBR();
          this.formataDatasForm();
          this.criarCliente({
            cliente: this.clienteLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.formataDatasDB();
          this.$root.snackbar.show({
            type: "success",
            message: "Cliente criado!!!",
          });
          this.tituloForm = "Editar Cliente";
          break;
        default:
          this.formataDatasForm();
          this.actEditarCliente({
            cliente: this.clienteLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.formataDatasDB();
          this.$root.snackbar.show({
            type: "success",
            message: "Cliente Salvo!",
          });
      }
      //this.resetar();
    },

    sincronizar(novoCliente) {
      this.clienteLocal = Object.assign(
        {},
        novoCliente || {
          id_cliente: null,
          data_cadastro: null,
          ultima_compra: null,
          data_nac_cli: null,
          data_nac_conju: null,
          data_consulta: null,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarCliente();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.clienteLocal.ultima_compra = this.clienteSelecionado.ultima_compra;
      // this.clienteLocal.email = this.clienteSelecionado.email;
      // this.clienteLocal.cliente = this.clienteSelecionado.cliente;
      // this.clienteLocal.senha = this.clienteSelecionado.senha;
      // this.clienteLocal.ativo = this.clienteSelecionado.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
